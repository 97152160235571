<template>
  <el-form label-width="120px"
           label-position="left"
           size="small">
    <el-form-item label-width="0px">
      <el-button size="small"
                 type="primary"
                 icon="el-icon-edit"
                 @click="open()">编辑交互事件</el-button>
    </el-form-item>
    <draggable ghost-class="ghost"
               class="transfer__menu menu__ul"
               :group="{ name: 'event' }"
               v-if="contain.activeObj.child.paramList&&contain.activeObj.child.paramList.length!==0"
               :list="contain.activeObj.child.paramList"
               :animation="300">
      <div class="menu__item"
           @click="open(index)"
           v-for="(item,index) in contain.activeObj.child.paramList"
           :key="index">
        <div class="menu__label">
          <el-button type="primary"
                     size="mini">{{getEventLabel(item.event)}}</el-button>
          <el-button type="success"
                     size="mini">{{getTypeLabel(item.type)}}</el-button>
        </div>
        <div class="menu__menu"
             @click.stop="delParamsList(index)">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </draggable>
    <el-empty v-else
              description="暂无交互事件">
      <template #image>
        <svg-icon icon-class="empty" />
      </template>
    </el-empty>
    <el-dialog :visible.sync="box"
               :close-on-click-modal="false"
               class="avue-dialog"
               title="交互编辑器"
               width="80%">
      <el-button size="small"
                 type="primary"
                 icon="el-icon-plus"
                 @click="handleTabsAdd()">
        新增交互事件
      </el-button>
      <br /> <br />
      <el-tabs class="menu__tabs"
               v-if="box"
               closable
               @edit="handleTabsEdit"
               @tab-click="handleTabClick"
               v-model="tabs">
        <template v-for="(item,index) in list">
          <el-tab-pane :name="index+''"
                       :key="index">
            <span slot="label">{{getTitle(item,index)}}</span>
          </el-tab-pane>
        </template>
      </el-tabs>
      <template v-if="list&&list.length!=0">
        <template v-for="(item,index) in list">
          <div class="transfer__list avue-flex"
               v-if="index==tabs"
               :key="index">
            <div class="transfer__menu menu__ul">
              <div class="menu__item is-select">
                <avue-select :dic="dicOption.transferEvent"
                             v-model="item.event"
                             size="mini"
                             placeholder="请选择事件，默认为click事件"></avue-select>
              </div>
              <div class="menu__item"
                   @click="item.type=citem.value"
                   :class="{'is-active':citem.value==item.type}"
                   v-for="(citem,cindex) in dic.type"
                   :key="cindex">
                <div class="menu__label">{{citem.label}}</div>
              </div>
            </div>
            <el-form-item label-width="0"
                          :key="index">
              <template v-if="item.type=='params'">
                <el-form-item label="子组件">
                  <avue-select multiple
                               v-model="item.index"
                               :dic="childrenDic"
                               :props="{label: 'name',value: 'index'}">
                  </avue-select>
                </el-form-item>
                <el-form-item label=""
                              label-width="0">
                  <el-button type="primary"
                             icon="el-icon-plus"
                             @click="item.child.push({})">新增参数</el-button>
                </el-form-item>
                <template v-for="(citem,cindex) in item.child">
                  <div :key="cindex">
                    <el-form-item label="参数名称">
                      <el-input v-model="citem.name"
                                placeholder="参数名称">
                        <el-button type="danger"
                                   slot="append"
                                   icon="el-icon-delete"
                                   @click="item.child.splice(cindex,1)"></el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="映射字段">
                      <avue-input v-model="citem.value"
                                  placeholder="映射字段"></avue-input>
                    </el-form-item>
                    <br />
                  </div>
                </template>
              </template>
              <template v-if="item.type=='group'">
                <el-form-item label="跳转大屏">
                  <avue-select :dic="contain.config.group"
                               v-model="item.group"
                               placeholder="请选择大屏对象"
                               :props="{label:'name',value:'id'}">
                  </avue-select>
                </el-form-item>
              </template>
              <template v-if="item.type=='href'">
                <el-form-item label="新窗口">
                  <avue-switch v-model="item.target"></avue-switch>
                </el-form-item>
                <el-form-item label="跳转大屏">
                  <avue-input v-model="item.src"
                              placeholder="请输入跳转地址">
                  </avue-input>
                </el-form-item>
                <el-form-item label=""
                              label-width="0">
                  <el-button type="primary"
                             icon="el-icon-plus"
                             @click="item.child.push({})">新增参数</el-button>
                </el-form-item>
                <template v-for="(citem,cindex) in item.child">
                  <div :key="cindex">
                    <el-form-item label="参数名称">
                      <el-input v-model="citem.name"
                                placeholder="参数名称">
                        <el-button type="danger"
                                   slot="append"
                                   icon="el-icon-delete"
                                   @click="item.child.splice(cindex,1)"></el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="映射字段">
                      <avue-input v-model="citem.value"
                                  placeholder="映射字段"></avue-input>
                    </el-form-item>
                    <br />
                  </div>
                </template>
              </template>
              <template v-if="item.type=='display'">
                <el-form-item label="子组件">
                  <avue-select multiple
                               v-model="item.index"
                               :dic="childrenDic"
                               :props="{label: 'name',value: 'index'}">
                  </avue-select>
                </el-form-item>
                <el-form-item label="操作">
                  <avue-select v-model="item.displayType"
                               :dic="dic.displayType">
                  </avue-select>
                </el-form-item>
              </template>
              <template v-if="item.type=='data'">
                <el-form-item label="子组件">
                  <avue-select multiple
                               v-model="item.index"
                               :dic="childrenDic"
                               :props="{label: 'name',value: 'index'}">
                  </avue-select>
                </el-form-item>
              </template>
              <template v-if="item.type=='dialog'">
                <el-form-item label="弹窗标题">
                  <avue-input v-model="item.title"
                              aria-placeholder="请输入弹窗标题">
                  </avue-input>
                </el-form-item>
                <el-form-item label="子组件">
                  <avue-select multiple
                               v-model="item.index"
                               :dic="childrenDic"
                               :props="{label: 'name',value: 'index'}">
                  </avue-select>
                </el-form-item>
                <el-form-item label="大屏">
                  <avue-select :dic="contain.config.group"
                               v-model="item.group"
                               placeholder="请选择大屏对象"
                               :props="{label:'name',value:'id'}">
                  </avue-select>
                </el-form-item>
              </template>
            </el-form-item>
          </div>

        </template>
      </template>
      <el-empty v-else
                description="暂无交互事件">
        <template #image>
          <svg-icon icon-class="empty" />
        </template>
      </el-empty>

      <span class="avue-dialog__footer avue-dialog__footer--right">
        <el-button size="small"
                   @click="close()">取 消</el-button>
        <el-button type="primary"
                   @click="submit()"
                   size="small">确 定</el-button>
      </span>
    </el-dialog>

  </el-form>
</template>

<script>
import { dicOption } from '@avue/avue-data/option/config'
export default {
  inject: ["contain"],
  data () {
    return {
      list: [],
      dicOption,
      tabs: 0,
      box: false,
      dic: {
        displayType: [{
          label: '隐藏',
          value: 'none'
        }, {
          label: '显示',
          value: 'block'
        }, {
          label: '显示/隐藏',
          value: ''
        }],
        type: [{
          label: '传参',
          value: 'params'
        }, {
          label: '跳转链接',
          value: 'href'
        }, {
          label: '跳转大屏',
          value: 'group'
        }, {
          label: '显隐',
          value: 'display'
        }, {
          label: '请求数据',
          value: 'data'
        }, {
          label: '弹窗',
          value: 'dialog'
        }]
      }
    }
  },
  computed: {
    childrenDic () {
      return this.contain.list
    }
  },
  methods: {
    open (index = 0) {
      this.list = this.deepClone(this.contain.activeObj.child.paramList)
      this.tabs = index + ''
      this.box = true;

    },
    submit () {
      this.$set(this.contain.activeObj.child, 'paramList', this.list)
      this.close()
    },
    close () {
      this.box = false;
    },
    getTitle (item, index) {
      return `${index + 1}.${this.getEventLabel(item.event)}__${this.getTypeLabel(item.type)}`
    },
    getEventLabel (event) {
      return (dicOption.transferEvent.find(ele => ele.value == event) || {}).label
    },
    getTypeLabel (value) {
      return (this.dic.type.find(ele => ele.value == value) || {}).label
    },
    handleTabsAdd () {
      this.addParamsList()
      this.tabs = this.list.length - 1 + ''
    },
    handleTabsEdit (targetName, action) {
      if (action === 'add') {
        this.addParamsList()
        this.tabs = this.list.length - 1 + ''
      } else if (action === 'remove') {
        this.delParamsList(targetName, true)
        let index = this.tabs;
        this.tabs = (targetName > Number(index) ? this.tabs : this.tabs - 1) + ''
      }
    },
    delParamsList (index, flag) {
      if (flag) {
        this.list.splice(index, 1)
      } else {
        this.contain.activeObj.child.paramList.splice(index, 1)
      }

    },
    addParamsList () {
      if (!this.list) {
        this.list = []
      }
      this.list.push({
        event: 'clickFormatter',
        index: [],
        type: 'params',
        child: []
      })
    },
  }
}
</script>

<style lang="scss">
.transfer__list {
  padding-top: 10px;
  align-items: flex-start;
  .menu__ul {
    padding-left: 0;
    padding-right: 20px;
    padding-top: 10px;
    width: 200px;
    border-right: 1px solid #161618;
  }
}
.transfer__menu {
  padding: 0 10px;
  .menu__label {
    width: 100% !important;
    padding-right: 10px;
    .el-button--success {
      min-width: 80px;
    }
  }
  .menu__item {
    padding: 10px !important;
    border: 1px solid #232323 !important;
    background-color: #2a2a2b !important;
  }
  .is-select {
    padding: 5px 10px !important;
    width: 100% !important;
    .el-input__inner,
    .el-select {
      width: 100% !important;
    }
  }
}
</style>