<template>
  <el-form label-width="120px"
           class="event"
           label-position="left"
           size="small">
    <el-form-item label-width="0px">
      <el-button size="small"
                 type="primary"
                 icon="el-icon-edit"
                 @click="open()">编辑组件基础事件</el-button>
    </el-form-item>

    <template v-for="(item,index) in list">
      <div :key="index"
           v-if="item.prop?contain.validProp('labelFormatterList'):true">
        <div class="event__content">
          <avue-highlight height="300"
                          :value="'//'+item.label+'\n'+(contain.activeObj[item.value] || '')"></avue-highlight>
        </div>
      </div>
    </template>
    <el-dialog :visible.sync="box"
               :close-on-click-modal="false"
               class="avue-dialog"
               title="事件编辑器"
               width="80%">
      <other-list>
        <div class="item">
          <el-button type="primary"
                     size="mini"
                     @click="$refs.codeTip.open()"
                     icon="el-icon-notebook-2">说明</el-button>
        </div>
        <div class="item">
          <el-button icon="el-icon-star-off"
                     type="primary"
                     size="mini"
                     @click="handleSc()">加入代码库</el-button>
        </div>
        <div class="item">
          <el-button icon="el-icon-download"
                     type="primary"
                     size="mini"
                     @click="handleImport()">从代码库导入</el-button>
        </div>
      </other-list>
      <el-tabs class="menu__tabs"
               v-if="box"
               @tab-click="handleTabClick"
               stretch
               v-model="tabs">
        <template v-for="(item,index) in list">
          <el-tab-pane :name="index+''"
                       v-if="item.prop?contain.validProp('labelFormatterList'):true"
                       :key="index">
            <span slot="label">{{item.label}}</span>
          </el-tab-pane>
        </template>
      </el-tabs>
      <br />
      <monaco-editor v-model="text[type]"
                     height="200"
                     language="javascript"></monaco-editor>
      <span class="avue-dialog__footer avue-dialog__footer--right">
        <el-button size="small"
                   @click="close()">取 消</el-button>
        <el-button type="primary"
                   @click="submit"
                   size="small">确 定</el-button>
      </span>
      <code-tip ref="codeTip"
                :type="type"></code-tip>
    </el-dialog>
    <el-dialog title="代码库"
               append-to-body
               class="avue-dialog"
               :visible.sync="api.box"
               width="70%">
      <fun-list v-if="api.box"
                @change="handleApiChange"
                :menu="false"></fun-list>
    </el-dialog>
  </el-form>
</template>

<script>
import funList from '@avue/avue-data/page/list/fun'
import { funEval } from '@avue/avue-data/utils/utils'
import otherList from '@avue/avue-data/page/group/other-list'
import MonacoEditor from '@avue/avue-data/page/components/monaco-editor'
import codeTip from '@avue/avue-data/page/group/code-tip'
import { dicOption } from '@avue/avue-data/option/config'
import { addObj, getObj } from '@avue/avue-data/api/fun'
export default {
  inject: ["contain"],
  components: { MonacoEditor, codeTip, otherList, funList },
  provide () {
    return {
      contain: this.contain,
    };
  },
  data () {
    return {
      api: {
        box: false,
        item: {}
      },
      text: {},
      list: dicOption.transferEvent.concat([{
        label: '提示事件',
        value: 'formatter',
        prop: 'labelFormatterList',
        temp: 'temp1'
      }, {
        label: '标题事件',
        value: 'labelFormatter',
        prop: 'labelFormatterList',
        temp: 'temp1'
      }]),
      temp: `(params,refs)=>{
    console.log(params,refs)
}`,
      temp1: `(name,data)=>{
    console.log(name,data)
    return ''
}`,
      box: false,
      type: '',
      tabs: ''
    }
  },
  methods: {
    handleApiChange (val) {
      this.api.item = val
      getObj(this.api.item.id).then(res => {
        const data = res.data.data;
        this.text[this.type] = data.content
        this.api.box = false;
        this.api.item = {}
        this.$message.success('导入成功')
      })
    },
    handleImport () {
      this.api.box = true
    },
    handleSc () {
      this.$DialogForm.show({
        title: '收藏代码',
        width: '400px',
        menuPosition: 'right',
        option: {
          labelWidth: 80,
          submitText: '确认',
          span: 24,
          column: [{
            label: "名称",
            prop: "name",
            rules: [{
              required: true,
              message: "请输入名称",
              trigger: "blur"
            }],
          }, {
            label: "类别",
            prop: "type",
            type: 'select',
            dicData: dicOption.funType,
            rules: [{
              required: true,
              message: "请选择类别",
              trigger: "blur"
            }],
          }]
        },
        callback: (res) => {
          const form = res.data || {}
          addObj({
            type: form.type,
            name: form.name,
            content: typeof (this.text[this.type]) == 'object' ? JSON.stringify(this.text[this.type]) : this.text[this.type]
          }).then(() => {
            res.close()
            this.$message.success('收藏成功')
          })

        }
      })

    },
    submit () {
      let label, type;
      try {
        this.list.forEach(ele => {
          type = ele.value
          label = ele.label;
          let value = this.text[type];
          try {
            funEval(value);
            this.contain.activeObj[type] = value
          } catch (err) {
            throw Error(err)
          }
        })
        this.close()
      } catch (error) {
        console.log(error);
        this.$message.error(`${label}${type}-格式校验有误`)
      }
    },
    handleTabClick (tab) {
      let index = tab.name
      let obj = this.list[index]
      this.type = obj.value;
      let value = this.contain.activeObj[obj.value] || this[obj.temp]
      this.$set(this.text, obj.value, value)
    },
    open () {
      this.type = this.list[0].value;
      let index = this.list.findIndex(ele => ele.value == this.type)
      this.tabs = index;
      let obj = this.list.find(ele => ele.value == this.type)
      let value = this.contain.activeObj[obj.value] || this[obj.temp]
      this.$set(this.text, obj.value, value)
      this.box = true;
    },
    close () {
      this.text = []
      this.box = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.event {
  &__menu {
    margin-bottom: 10px;
    padding-right: 10px;
  }
  &__label {
    width: 100%;
    padding: 5px 10px 10px 10px;
    box-sizing: border-box;
    font-size: 13px;
    color: #fff;
  }
  &__content {
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
</style>
